if (0 < document.querySelectorAll('.employees__slider').length) {
  var featuredPostsCarousel = new Swiper('.employees__slider', {
    // Optional parameters
    direction: 'horizontal',
    slidesPerView: 1,
    effect: 'fade',
    // Pagination
    pagination: {
      el: '.employees__slider__pagination.swiper-pagination',
      clickable: true
    },
    // Navigation arrows
    navigation: {
      nextEl: '.employees__slider__navigation .swiper-button-next',
      prevEl: '.employees__slider__navigation .swiper-button-prev'
    }
  });
}